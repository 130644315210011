import constants from 'appConstants';
import { capitalize, get, isString } from 'lodash-es';
import dayjs from 'dayjs';

export const SHIFT_ACTIONS = {
    LEFT: 'shift_left',
    RIGHT: 'shift_right',
};

export const ACTIONS_COMMAND = {
    START_TRANSPORTATION: 'startTransportation',
    END_TRANSPORTATION: 'endTransportation',
    START_VISIT: 'startVisit',
    END_VISIT: 'endVisit',
    START_SUPER: 'startSuper',
    END_SUPER: 'endSuper',
};

const getTestersActionsOptions = ({ isEMTester, isFLTester }) => {
    if (isEMTester) {
        return [
            {
                label: 'Gripper Action',
                value: constants.COMMANDS.GRIPPER_ACTION,
            },
        ];
    }
    if (isFLTester) {
        return [
            {
                label: 'Gripper Action',
                value: constants.COMMANDS.GRIPPER_ACTION,
            },
            {
                label: 'Wedge Lock',
                value: constants.COMMANDS.WEDGE_LOCK,
            },
            {
                label: 'Wedge Unlock',
                value: constants.COMMANDS.WEDGE_UNLOCK,
            },
            {
                label: 'Pull Out Frame',
                value: constants.COMMANDS.PULLOUT_FRAME,
            },
            {
                label: 'Put Down Frame',
                value: constants.COMMANDS.PUT_DOWN_FRAME,
            },
        ];
    }
};

export const getActionsOptions = ({ isEMTester, isFLTester }) => {
    if (isEMTester || isFLTester) {
        return getTestersActionsOptions({ isEMTester, isFLTester });
    }
    return [
        {
            label: 'Align To',
            value: constants.COMMANDS.ALIGN_TO,
        },
        {
            label: 'Engage Frame',
            value: constants.COMMANDS.ENGAGE_FRAME,
        },
        {
            label: 'Extract Frame',
            value: constants.COMMANDS.EXTRACT_FRAME,
        },
        {
            label: 'Insert Frame',
            value: constants.COMMANDS.INSERT_FRAME,
        },
        {
            label: 'Gripper Action',
            value: constants.COMMANDS.GRIPPER_ACTION,
        },
        {
            label: 'Wedge Lock',
            value: constants.COMMANDS.WEDGE_LOCK,
        },
        {
            label: 'Wedge Unlock',
            value: constants.COMMANDS.WEDGE_UNLOCK,
        },
        {
            label: 'Open Hive',
            value: constants.COMMANDS.OPEN_HIVE,
        },
        {
            label: 'Close Hive',
            value: constants.COMMANDS.CLOSE_HIVE,
        },
        {
            label: 'Spread Hive',
            value: constants.COMMANDS.SPREAD_HIVE,
        },
        {
            label: 'Scan Frame',
            value: constants.COMMANDS.SCAN_FRAME,
        },
        {
            label: 'Fill Syrup',
            value: constants.COMMANDS.FILL_SYRUP,
        },
        {
            label: 'Move Frame',
            value: constants.COMMANDS.MOVE_FRAME,
        },
        {
            label: 'Count Bees Frame',
            value: constants.COMMANDS.COUNT_BEES_FRAME,
        },
        {
            label: 'Scan Bundle',
            value: constants.COMMANDS.SCAN,
        },
        {
            label: 'Incubate Bundle',
            value: constants.COMMANDS.INCUBATE,
        },
        {
            label: 'Feed Bundle',
            value: constants.COMMANDS.FILL_FEEDER,
        },
        {
            label: 'Count Bees Bundle',
            value: constants.COMMANDS.COUNT_BEES,
        },
        {
            label: 'Go to',
            value: constants.COMMANDS.GO_TO,
        },
        {
            label: 'Pull Out Frame',
            value: constants.COMMANDS.PULLOUT_FRAME,
        },
        {
            label: 'Put Down Frame',
            value: constants.COMMANDS.PUT_DOWN_FRAME,
        },
        {
            label: 'Acquire Image',
            value: constants.COMMANDS.ACQUIRE_IMAGE,
        },
        {
            label: 'Add Syrup To Feeder',
            value: constants.COMMANDS.ADD_SYRUP_TO_FEEDER,
        },
        {
            label: 'Move to transportation',
            value: constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION,
        },
        {
            label: 'Move to Visit Position',
            value: constants.COMMANDS.MOVE_TO_VISIT_POSITION,
        },
        {
            label: 'Make Space',
            value: constants.COMMANDS.MAKE_SPACE,
        },
        {
            label: 'Partial Frame Layout',
            value: constants.COMMANDS.PARTIAL_FRAME_LAYOUT,
        },
        {
            label: 'Frame Scale Calibrate Zero Offset',
            value: constants.COMMANDS.FRAME_SCALE_CALIBRATE_ZERO_OFFSET,
        },
        {
            label: 'Frame Scale Calibrate Factor',
            value: constants.COMMANDS.FRAME_SCALE_CALIBRATE_FACTOR,
        },
        {
            label: 'Is Feeder Spilling',
            value: constants.COMMANDS.IS_FEEDER_SPILLING,
        },
    ];
};

export const slotsOptions = () =>
    new Array(constants.SLOTS_PER_BHOME_4_STATION).fill(undefined).map((item, index) => ({
        label: index + 1,
        value: index,
    }));

export const getSlotIndexes = ({ hive, slots, needRelativeSlotIndex = true }) =>
    (hive === 'a'
        ? slots.slice(0, constants.SLOTS_PER_BHOME_4_STATION)
        : slots.slice(constants.SLOTS_PER_BHOME_4_STATION * -1)
    ).map(slot => ({
        slotIndex: slot,
        ...(needRelativeSlotIndex
            ? {
                  slot: hive === 'a' ? slot : slot - constants.SLOTS_PER_BHOME_4_STATION,
              }
            : {}),
    }));

export const cameraOptions = [
    {
        label: 'Tech cam',
        value: 'techCam',
    },
    {
        label: 'Scan A',
        value: 'scanA',
    },
    {
        label: 'Scan B',
        value: 'scanB',
    },
];

const getTestersAxisOptions = ({ isEMTester, isGripperTester, isFLTester }) => {
    if (isEMTester) {
        return [
            {
                label: 'Z-axis',
                value: 'Z',
            },
            {
                label: 'Frame gripper',
                value: 'G',
            },
        ];
    }
    if (isGripperTester) {
        return [
            {
                label: 'Frame gripper',
                value: 'G',
            },
            {
                label: 'GA',
                value: 'G1',
            },
            {
                label: 'GB',
                value: 'G2',
            },
        ];
    }

    if (isFLTester) {
        return [
            {
                label: 'Y-axis',
                value: 'Y',
            },
            {
                label: 'Z-axis',
                value: 'Z',
            },
            {
                label: 'Frame gripper',
                value: 'G',
            },
            {
                label: 'GA',
                value: 'G1',
            },
            {
                label: 'GB',
                value: 'G2',
            },
        ];
    }
};

export const axisOptions = ({ isEMTester, isGripperTester, isFLTester }) => {
    if (isEMTester || isGripperTester || isFLTester) {
        return getTestersAxisOptions({ isEMTester, isGripperTester, isFLTester });
    }

    return [
        {
            label: 'X-axis',
            value: 'X',
        },
        {
            label: 'Y-axis',
            value: 'Y',
        },
        {
            label: 'Z-axis',
            value: 'Z',
        },
        {
            label: 'Frame gripper',
            value: 'G',
        },
        {
            label: 'GA',
            value: 'G1',
        },
        {
            label: 'GB',
            value: 'G2',
        },
        {
            label: 'Syrup Pump',
            value: 'syrup_pump',
        },
    ];
};

const getBeeGateOptions = state => [
    {
        text: 'Bee gate 1',
        value: 'GA',
        position: get(state, 'G1 motor.step_mm', '-'),
        encoder: get(state, 'G1 motor.pos_encoder', '-'),
        limit: get(state, 'G1 motor.minSense', '-'),
        speed: get(state, 'G1 motor.speed', '-'),
        status: get(state, 'G1 motor.state', '-'),
    },
    {
        text: 'Bee gate 2',
        value: 'GB',
        position: get(state, 'G2 motor.step_mm', '-'),
        encoder: get(state, 'G2 motor.pos_encoder', '-'),
        limit: get(state, 'G2 motor.minSense', '-'),
        speed: get(state, 'G2 motor.speed', '-'),
        status: get(state, 'G2 motor.state', '-'),
    },
];

export const getAxisOptions = state => [
    {
        text: 'X-axis',
        value: 'X',
        position: get(state, 'X1 motor.step_mm', get(state, 'X motor.step_mm', '-')),
        encoder: get(state, 'X1 motor.pos_encoder', get(state, 'X motor.pos_encoder', '-')),
        limit: get(state, 'X1 motor.minSense', get(state, 'X motor.minSense', '-')),
        speed: get(state, 'X1 motor.speed', get(state, 'X motor.speed', '-')),
        status: get(state, 'X1 motor.state', get(state, 'X motor.state', '-')),
    },
    {
        text: 'Y-axis',
        value: 'Y',
        position: get(state, 'Y motor.step_mm', '-'),
        encoder: get(state, 'Y motor.pos_encoder', '-'),
        limit: get(state, 'Y motor.minSense', '-'),
        speed: get(state, 'Y motor.speed', '-'),
        status: get(state, 'Y motor.state', '-'),
    },
    {
        text: 'Z-axis',
        value: 'Z',
        position: get(state, 'Z motor.step_mm', '-'),
        encoder: get(state, 'Z motor.pos_encoder', '-'),
        limit: get(state, 'Z motor.minSense', '-'),
        speed: get(state, 'Z motor.speed', '-'),
        status: get(state, 'Z motor.state', '-'),
    },
    {
        text: 'T-axis',
        value: 'T',
        position: get(state, 'T motor.step_mm', '-'),
        encoder: get(state, 'T motor.pos_encoder', '-'),
        limit: get(state, 'T motor.minSense', '-'),
        speed: get(state, 'T motor.speed', '-'),
        status: get(state, 'T motor.state', '-'),
    },
    ...getBeeGateOptions(state),
    {
        text: 'Slot sniffer',
        value: 'S',
        position: get(state, 'S motor.step_mm', '-'),
        encoder: get(state, 'S motor.pos_encoder', '-'),
        limit: get(state, 'S motor.minSense', '-'),
        speed: get(state, 'S motor.speed', '-'),
        status: get(state, 'S motor.state', '-'),
    },
    {
        text: 'G motor',
        value: 'G',
        position: get(state, 'G motor.step_mm', '-'),
        encoder: get(state, 'G motor.pos_encoder', '-'),
        limit: get(state, 'G motor.minSense', '-'),
        speed: get(state, 'G motor.speed', '-'),
        status: get(state, 'G motor.state', '-'),
    },
    {
        text: 'BEM motor',
        value: 'BEM',
        position: get(state, 'BEM motor.step_mm', '-'),
        encoder: get(state, 'BEM motor.pos_encoder', '-'),
        limit: get(state, 'BEM motor.minSense', '-'),
        speed: get(state, 'BEM motor.speed', '-'),
        status: get(state, 'BEM motor.state', '-'),
    },
    {
        text: 'Shaker',
        value: 'Shaker',
        position: '-',
        encoder: '-',
        limit: '-',
        speed: '-',
        status: get(state, 'shaker', '-'),
    },
];

export const getSensorOptions = state => [
    {
        text: 'Internal Temperature',
        value: 'Internal Temperature',
        status: (() => {
            const temp = get(state, 'env_int.temperature', get(state, 'internal_temperature', '-'));
            return typeof temp === 'number' ? `${temp?.toFixed(2)} °C` : '-';
        })(),
    },
    {
        text: 'External Temperature',
        value: 'External Temperature',
        status: (() => {
            const temp = get(state, 'env_ext.temperature', get(state, 'external_temperature', '-'));
            return typeof temp === 'number' ? `${temp?.toFixed(2)} °C` : '-';
        })(),
    },
    {
        text: 'EC Temperature',
        value: 'EC Temperature',
        status: (() => {
            const temp = get(state, 'env_ec.temperature', get(state, 'electrical_cabinet_temperature', '-'));
            return typeof temp === 'number' ? `${temp?.toFixed(2)} °C` : '-';
        })(),
    },
    {
        text: 'Internal Humidity',
        value: 'Internal Humidity',
        status: (() => {
            const humidity = get(state, 'env_int.humidity', get(state, 'internal_humidity', '-'));
            return typeof humidity === 'number' ? `${humidity?.toFixed(2)} %` : '-';
        })(),
    },
    {
        text: 'External Humidity',
        value: 'External Humidity',
        status: (() => {
            const humidity = get(state, 'env_ext.humidity', get(state, 'external_humidity', '-'));
            return typeof humidity === 'number' ? `${humidity?.toFixed(2)} %` : '-';
        })(),
    },
    {
        text: 'EC Humidity',
        value: 'EC Humidity',
        status: (() => {
            const humidity = get(state, 'env_ec.humidity', get(state, 'electrical_cabinet_humidity', '-'));
            return typeof humidity === 'number' ? `${humidity?.toFixed(2)} %` : '-';
        })(),
    },
    {
        text: 'Frame weight',
        value: 'Frame weight',
        status: (() => {
            const weight = get(state, 'frameWeight', '-');
            return typeof weight === 'number' ? `${Math.round(weight)} gr` : '-';
        })(),
    },
    {
        text: 'Last LC calibration',
        value: 'Last LC calibration',
        status: `${get(state, 'lcCalibration.status', '-')} at ${
            get(state, 'lcCalibration.sent_at', null)
                ? dayjs(get(state, 'lcCalibration.sent_at', '-')).format('DD-MM-YYYY HH:mm')
                : '-'
        }`,
    },
    {
        text: 'Emergency Button',
        value: 'Emergency Button',
        status: get(state, 'emergency_button', '-'),
    },
    {
        text: 'Doors',
        value: 'Doors',
        status: get(state, 'doors', '-'),
    },
    {
        text: 'Standby Mode',
        value: 'Standby Mode',
        status: get(state, 'standby_mode', '-'),
    },
    {
        text: 'Cellular Reception',
        value: 'Cellular Reception',
        status: (() => {
            const reception = get(state, 'cellular_reception', '-');
            return typeof reception === 'number' ? reception?.toFixed(2) : '-';
        })(),
    },
    {
        text: 'BMS',
        value: 'BMS',
        status: (() => {
            const bms = get(state, 'bms', '-');
            return typeof bms === 'number' ? bms?.toFixed(2) : '-';
        })(),
    },
    {
        text: 'Syrup Level',
        value: 'Syrup Level',
        status: (() => {
            const sls = get(state, 'syrup_level_sensors', '-');
            return `B:${sls?.bottom_sensor ?? '-'} / M:${sls?.middle_sensor ?? '-'} / T:${sls?.top_sensor ?? '-'}`;
        })(),
    },
];

export const getSequenceOptions = state => [
    {
        text: 'Task',
        value: 'Task',
        status: get(state, 'runningTask', '-'),
    },
    {
        text: 'Sequence',
        value: 'Sequence',
        status: get(state, 'runningSequence', '-'),
    },
    {
        text: 'Hive Status',
        value: 'Hive Status',
        status: (state => {
            const isClosed = get(state, 'areAllHivesClosed', '-');
            if (isString(isClosed)) {
                return isClosed;
            }
            return isClosed ? 'Closed' : 'Open';
        })(state),
    },
];

export const frameTypeOptions = ({
    onlyPartition = false,
    nonPartition = false,
    onlyFeeder = false,
    nonQueen = false,
    nonStaticPartition = false,
}) => {
    if (onlyPartition) {
        return [
            {
                label: 'Partition',
                value: constants.FRAME_TYPES.PARTITION,
            },
            {
                label: 'Static Partition',
                value: constants.FRAME_TYPES.STATIC_PARTITION,
            },
        ];
    }

    if (onlyFeeder) {
        return [
            {
                label: 'Feeder',
                value: constants.FRAME_TYPES.FEEDER,
            },
        ];
    }

    if (nonPartition) {
        return [
            {
                label: 'Comb',
                value: constants.FRAME_TYPES.COMB_FRAME,
            },
            {
                label: 'Feeder',
                value: constants.FRAME_TYPES.FEEDER,
            },
            {
                label: 'Queen Excluder',
                value: constants.FRAME_TYPES.QUEEN_EXCLUDER,
            },
        ];
    }

    if (nonQueen) {
        return [
            {
                label: 'Comb Frame',
                value: constants.FRAME_TYPES.COMB_FRAME,
            },
            {
                label: 'Partition',
                value: constants.FRAME_TYPES.PARTITION,
            },
            {
                label: 'Feeder',
                value: constants.FRAME_TYPES.FEEDER,
            },
        ];
    }

    if (nonStaticPartition) {
        return [
            {
                label: 'Comb',
                value: constants.FRAME_TYPES.COMB_FRAME,
            },
            {
                label: 'Partition',
                value: constants.FRAME_TYPES.PARTITION,
            },
            {
                label: 'Feeder',
                value: constants.FRAME_TYPES.FEEDER,
            },
            {
                label: 'Queen Excluder',
                value: constants.FRAME_TYPES.QUEEN_EXCLUDER,
            },
        ];
    }

    return [
        {
            label: 'Comb',
            value: constants.FRAME_TYPES.COMB_FRAME,
        },
        {
            label: 'Partition',
            value: constants.FRAME_TYPES.PARTITION,
        },
        {
            label: 'Static Partition',
            value: constants.FRAME_TYPES.STATIC_PARTITION,
        },
        {
            label: 'Feeder',
            value: constants.FRAME_TYPES.FEEDER,
        },
        {
            label: 'Queen Excluder',
            value: constants.FRAME_TYPES.QUEEN_EXCLUDER,
        },
    ];
};

export const frameStationOptions = ({ partialStationOptions = false }) => {
    if (partialStationOptions) {
        return [
            { label: 'A', value: constants.STATIONS.A },
            { label: 'B', value: constants.STATIONS.B },
            { label: 'System Slot', value: constants.STATIONS.SYSTEM_SLOT },
            { label: 'Feeding Dispenser', value: constants.STATIONS.FEEDING_DISPENSER },
            { label: 'Incubator', value: constants.STATIONS.INCUBATOR },
        ];
    }
    return Object.keys(constants.STATIONS).map(item => ({
        label: capitalize(item.split('_').join(' ')),
        value: constants.STATIONS[item],
    }));
};

export const stationOptions = Object.keys(constants.STATIONS).map(item => ({
    text: capitalize(item.split('_').join(' ')),
    value: constants.STATIONS[item],
}));

export const gripperOptions = [
    {
        label: 'Grip',
        value: 'grip',
    },
    {
        label: 'Ungrip',
        value: 'ungrip',
    },
];

export const untuckOptions = [
    {
        label: '-1',
        value: -1,
    },
    {
        label: '0',
        value: 0,
    },
    {
        label: '1',
        value: 1,
    },
];

export const convertOptionName = name => {
    switch (name) {
        case 'mcb':
        case 'fls':
        case 'fld':
            return name.toUpperCase();
        default:
            return name;
    }
};

export const partialAxisOptions = [
    { value: 'x', label: 'X' },
    { value: 'y', label: 'Y' },
    { value: 'all', label: 'All' },
];

export const moveToVisitPositionOptions = [
    { value: 'start', label: 'Start' },
    { value: 'end', label: 'End' },
];

export const getActionsOptionsForMonitoring = () => [
    {
        label: 'Cancel',
        value: constants.COMMANDS.CANCEL_PENDING_TASKS,
    },
    {
        label: 'Resume',
        value: constants.COMMANDS.RESUME_PENDING_TASKS,
    },
    {
        label: 'Initialize',
        value: constants.COMMANDS.INITIALIZE_ROBOT,
    },
    {
        label: 'Create layout',
        value: constants.COMMANDS.CREATE_LAYOUT,
    },
    {
        label: 'Refresh SIM Info',
        value: constants.COMMANDS.GET_SYS_INFO,
    },
    {
        label: 'What are you doing?',
        value: constants.COMMANDS.WHAT_ARE_YOU_DOING,
    },
    {
        label: 'Reboot',
        value: constants.COMMANDS.REBOOT_DEVICE,
    },
    {
        label: 'Transport',
        value: ACTIONS_COMMAND.START_TRANSPORTATION,
    },
    {
        label: 'End transport',
        value: ACTIONS_COMMAND.END_TRANSPORTATION,
    },
    {
        label: 'Visit',
        value: ACTIONS_COMMAND.START_VISIT,
    },
    {
        label: 'End visit',
        value: ACTIONS_COMMAND.END_VISIT,
    },
    {
        label: 'Super',
        value: ACTIONS_COMMAND.START_SUPER,
    },
    {
        label: 'End super',
        value: ACTIONS_COMMAND.END_SUPER,
    },
    {
        label: 'Frame Scale Calibrate Zero Offset',
        value: constants.COMMANDS.FRAME_SCALE_CALIBRATE_ZERO_OFFSET,
    },
    {
        label: 'Is Feeder Spilling',
        value: constants.COMMANDS.IS_FEEDER_SPILLING,
    },
    {
        label: 'Maintenance',
        value: constants.BHOME_STATUSES.MAINTENANCE,
    },
    {
        label: 'Receptive',
        value: constants.BHOME_STATUSES.RECEPTIVE,
    },
];
