import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SelectField } from '@beewise/select-field';
import Card from 'components/reusables/Card';
import analytics from 'utils/analytics';
import TechButton from 'components/reusables/TechButton';
import constants from 'appConstants';
import { noop } from 'lodash-es';
import { showToast } from '@beewise/toast';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { getActionsOptions } from '../../utils';
import { fetchCountBees, fetchFeed, fetchIncubate, fetchScan } from '../../actions';
import StationParams from './components/StationParams';
import ExtractFrameParams from './components/ExtractFrameParams';
import GripperActionParams from './components/GripperActionParams';
import OpenHiveParams from './components/OpenHiveParams';
import CloseHiveParams from './components/CloseHiveParams';
import ScanFrameParams from './components/ScanFrameParams';
import MoveFrameParams from './components/MoveFrameParams';
import ScanBundleParams from './components/ScanBundleParams';
import FillSyrupParams from './components/FillSyrupParams';
import FeedBundleParams from './components/FeedBundleParams';
import GoToParams from './components/GoToParams';
import PullOutFrameParams from './components/PullOutFrameParams';
import MakeSpaceParams from './components/MakeSpaceParams';
import MoveToVisitPositionParams from './components/MoveToVisitPositionParams';
import PartialFrameLayoutParams from './components/PartialFrameLayoutParams';
import { ACTIONS } from '../GeneralFunctions/components/ModeControl';
import FrameScaleCalibrateFactorParams from './components/FrameScaleCalibrateFactorParams';
import SpreadHiveParams from './components/SpreadHiveParams';

import './ActionCenterV4.scss';

const FrameActionsCenterV4 = ({ bhome, isEMTester, isFLTester }) => {
    const dispatch = useDispatch();
    const [action, setAction] = useState(null);
    const [params, setParams] = useState({});

    const actionsOptions = useMemo(() => getActionsOptions({ isEMTester, isFLTester }), [isEMTester, isFLTester]);

    useEffect(() => {
        setAction(null);
        setParams({});
    }, [bhome.id]);

    useEffect(() => {
        if (action === constants.COMMANDS.OPEN_HIVE) {
            setParams(params => ({
                ...params,
                untuck_direction: 0,
            }));
        }

        if (action === constants.COMMANDS.FILL_SYRUP) {
            setParams(params => ({
                ...params,
                shake: false,
            }));
        }

        if (action === constants.COMMANDS.MAKE_SPACE) {
            setParams(params => ({
                ...params,
                direction: -1,
            }));
        }

        if (action === constants.COMMANDS.SPREAD_HIVE) {
            setParams(params => ({
                ...params,
                untuck_direction: -1,
            }));
        }

        if (
            action === constants.COMMANDS.SCAN_FRAME ||
            action === constants.COMMANDS.EXTRACT_FRAME ||
            action === constants.COMMANDS.COUNT_BEES_FRAME
        ) {
            setParams(params => ({
                ...params,
                count_bees: true,
            }));
        }

        if (action === constants.COMMANDS.CLOSE_HIVE) {
            setParams(params => ({
                ...params,
                tuck: {
                    direction: 1,
                },
            }));
        }

        if (action === constants.COMMANDS.PUT_DOWN_FRAME) {
            setParams(params => ({
                ...params,
                only_bee_count: false,
            }));
        }

        if (action === constants.COMMANDS.MOVE_TO_VISIT_POSITION) {
            setParams(params => ({
                ...params,
                position_to_move_to: ACTIONS.START,
            }));
        }

        if (action === constants.COMMANDS.FRAME_SCALE_CALIBRATE_FACTOR) {
            setParams(params => ({
                ...params,
                weight_on_scale: null,
            }));
        }
    }, [action]);

    const hasMmParams = useMemo(
        () =>
            action === constants.COMMANDS.ALIGN_TO ||
            action === constants.COMMANDS.ENGAGE_FRAME ||
            action === constants.COMMANDS.EXTRACT_FRAME ||
            action === constants.COMMANDS.INSERT_FRAME ||
            action === constants.COMMANDS.OPEN_HIVE ||
            action === constants.COMMANDS.CLOSE_HIVE ||
            action === constants.COMMANDS.SCAN_FRAME ||
            action === constants.COMMANDS.COUNT_BEES_FRAME ||
            action === constants.COMMANDS.FILL_SYRUP ||
            action === constants.COMMANDS.ADD_SYRUP_TO_FEEDER ||
            action === constants.COMMANDS.MOVE_FRAME ||
            action === constants.COMMANDS.PUT_DOWN_FRAME ||
            action === constants.COMMANDS.ACQUIRE_IMAGE ||
            action === constants.COMMANDS.MAKE_SPACE ||
            action === constants.COMMANDS.PULLOUT_FRAME ||
            action === constants.COMMANDS.SPREAD_HIVE ||
            action === constants.COMMANDS.FRAME_SCALE_CALIBRATE_FACTOR,
        [action]
    );

    const handleActionsChange = useCallback(
        value => {
            setAction(value);
            setParams({});
        },
        [setAction, setParams]
    );

    const handleSendClick = useCallback(() => {
        analytics.fireFrameActionEvent({
            action,
            label: `Beehome ${bhome.id}`,
        });

        if (action === constants.COMMANDS.SCAN) {
            dispatch(
                fetchScan(bhome?.id, params?.slots, noop, error =>
                    showToast(error, {
                        toastType: 'toast-error',
                        position: 'bottom-center',
                    })
                )
            );
        } else if (action === constants.COMMANDS.COUNT_BEES) {
            dispatch(
                fetchCountBees(bhome?.id, params?.slots, noop, error =>
                    showToast(error, {
                        toastType: 'toast-error',
                        position: 'bottom-center',
                    })
                )
            );
        } else if (action === constants.COMMANDS.FILL_FEEDER) {
            dispatch(
                fetchFeed(bhome?.id, params?.slots, noop, error =>
                    showToast(error, {
                        toastType: 'toast-error',
                        position: 'bottom-center',
                    })
                )
            );
        } else if (action === constants.COMMANDS.INCUBATE) {
            dispatch(
                fetchIncubate(bhome?.id, params?.slots, noop, error =>
                    showToast(error, {
                        toastType: 'toast-error',
                        position: 'bottom-center',
                    })
                )
            );
        } else {
            dispatch(fetchSendTechnicianAction(bhome.id, { type: action, params }));
        }
    }, [dispatch, bhome.id, action, params]);

    const wrapperName = action === constants.COMMANDS.CLOSE_HIVE ? 'tuck' : null;

    return (
        <div className="frame-actions-center frame-actions-center-v4">
            <Card className="actions" title="Frame Actions">
                <div className="actions-flex-line">
                    <SelectField
                        value={action}
                        options={actionsOptions}
                        onChange={handleActionsChange}
                        placeholder="Select action"
                        className="nowrap"
                        size="small"
                    />
                    {hasMmParams && (
                        <StationParams
                            setParams={setParams}
                            params={params}
                            action={action}
                            wrapperName={wrapperName}
                        />
                    )}
                    {action === constants.COMMANDS.GRIPPER_ACTION && (
                        <GripperActionParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.EXTRACT_FRAME && (
                        <ExtractFrameParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.OPEN_HIVE && (
                        <OpenHiveParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.CLOSE_HIVE && (
                        <CloseHiveParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.SCAN_FRAME && (
                        <ScanFrameParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.FILL_SYRUP && (
                        <FillSyrupParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.MOVE_FRAME && (
                        <MoveFrameParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.MAKE_SPACE && (
                        <MakeSpaceParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.PARTIAL_FRAME_LAYOUT && (
                        <PartialFrameLayoutParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.FRAME_SCALE_CALIBRATE_FACTOR && (
                        <FrameScaleCalibrateFactorParams setParams={setParams} params={params} />
                    )}
                    {(action === constants.COMMANDS.SCAN ||
                        action === constants.COMMANDS.COUNT_BEES ||
                        action === constants.COMMANDS.INCUBATE) && <ScanBundleParams setParams={setParams} />}
                    {action === constants.COMMANDS.FILL_FEEDER && <FeedBundleParams setParams={setParams} />}
                    {action === constants.COMMANDS.GO_TO && <GoToParams setParams={setParams} params={params} />}
                    {action === constants.COMMANDS.PULLOUT_FRAME && (
                        <PullOutFrameParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.MOVE_TO_VISIT_POSITION && (
                        <MoveToVisitPositionParams setParams={setParams} params={params} />
                    )}
                    {action === constants.COMMANDS.SPREAD_HIVE && (
                        <SpreadHiveParams setParams={setParams} params={params} />
                    )}
                    <TechButton
                        disabled={!action}
                        className="btn-primary"
                        onClick={handleSendClick}
                        showToManufacturer
                        enabledInNonReceptive
                    >
                        Send
                    </TechButton>
                </div>
            </Card>
        </div>
    );
};

FrameActionsCenterV4.propTypes = {
    bhome: PropTypes.shape(),
    isEMTester: PropTypes.bool,
    isFLTester: PropTypes.bool,
};

export default FrameActionsCenterV4;
